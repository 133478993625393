import { AbstractLocaleMessageObject } from "@/i18n/AbstractLocaleMessageObject"

import { AllowedIpAddressJa } from "./AllowedIpAddressJa"
import { ApiConnectionInfoJa } from "./ApiConnectionInfoJa"
import { CommonAllSelectionPartsJa } from "./CommonAllSelectionPartsJa"
import { CommonConfirmDialogJa } from "./CommonConfirmDialogJa"
import { CommonDateSelectionFieldJa } from "./CommonDateSelectionFieldJa"
import { CommonDateTimeSelectionFieldJa } from "./CommonDateTimeSelectionFieldJa"
import { CommonPagingControlPartsJa } from "./CommonPagingControlPartsJa"
import { CommonTitleHeaderJa } from "./CommonTitleHeaderJa"
import { CsvAuthoriHistoryJa } from "./CsvAuthoriHistoryJa"
import { CsvAuthoriJa } from "./CsvAuthoriJa"
import { CsvNegativeJa } from "./CsvNegativeJa"
import { CsvUpdateJa } from "./CsvUpdateJa"
import { EventAssignDlgJa } from "./EventAssignDlgJa"
import { EventCsvDownloadDlgJa } from "./EventCsvDownloadDlgJa"
import { EventDetailJa } from "./EventDetailJa"
import { EventHoldDlgJa } from "./EventHoldDlgJa"
import { EventItemDisplayFormatEditJa } from "./EventItemDisplayFormatEditJa"
import { EventLabelDlgJa } from "./EventLabelDlgJa"
import { EventListJa } from "./EventListJa"
import { EventMemoDlgJa } from "./EventMemoDlgJa"
import { EventNegativeDlgJa } from "./EventNegativeDlgJa"
import { EventNgDlgJa } from "./EventNgDlgJa"
import { EventOkDlgJa } from "./EventOkDlgJa"
import { EventReportNegativeJa } from "./EventReportNegativeJa"
import { LoginInputJa } from "./LoginInputJa"
import { MenuLabelsJa } from "./MenuLabelsJa"
import { NegativeAddJa } from "./NegativeAddJa"
import { NegativeDetailJa } from "./NegativeDetailJa"
import { NegativeHistoryJa } from "./NegativeHistoryJa"
import { NegativeListJa } from "./NegativeListJa"
import { OperatorAddJa } from "./OperatorAddJa"
import { OperatorDetailJa } from "./OperatorDetailJa"
import { OperatorEditJa } from "./OperatorEditJa"
import { OperatorListJa } from "./OperatorListJa"
import { OperatorRoleListJa } from "./OperatorRoleListJa"
import { PasswordChangeJa } from "./PasswordChangeJa"
import { PasswordChangeRequestJa } from "./PasswordChangeRequestJa"
import { ReleasedRuleListJa } from "./ReleasedRuleListJa"
import { SystemErrorJa } from "./SystemErrorJa"
import { TopMenuBarJa } from "./TopMenuBarJa"
import { TrialProgressJa } from "./TrialProgressJa"
import { WhiteAddJa } from "./WhiteAddJa"
import { WhiteListJa } from "./WhiteListJa"

export class LabelsJa extends AbstractLocaleMessageObject {
  readonly passwordChange: PasswordChangeJa = new PasswordChangeJa()

  readonly negativeList: NegativeListJa = new NegativeListJa()

  readonly negativeHistory: NegativeHistoryJa = new NegativeHistoryJa()

  readonly eventDetail: EventDetailJa = new EventDetailJa()

  readonly eventCsvDownloadDlg: EventCsvDownloadDlgJa = new EventCsvDownloadDlgJa()

  readonly passwordChangeRequest: PasswordChangeRequestJa = new PasswordChangeRequestJa()

  readonly eventItemDisplayFormatEdit: EventItemDisplayFormatEditJa = new EventItemDisplayFormatEditJa()

  readonly menuLabels: MenuLabelsJa = new MenuLabelsJa()

  readonly eventNgDlg: EventNgDlgJa = new EventNgDlgJa()

  readonly operatorEdit: OperatorEditJa = new OperatorEditJa()

  readonly operatorDetail: OperatorDetailJa = new OperatorDetailJa()

  readonly operatorList: OperatorListJa = new OperatorListJa()

  readonly eventLabelDlg: EventLabelDlgJa = new EventLabelDlgJa()

  readonly negativeDetail: NegativeDetailJa = new NegativeDetailJa()

  readonly commonTitleHeader: CommonTitleHeaderJa = new CommonTitleHeaderJa()

  readonly negativeAdd: NegativeAddJa = new NegativeAddJa()

  readonly eventList: EventListJa = new EventListJa()

  readonly csvAuthoriHistory: CsvAuthoriHistoryJa = new CsvAuthoriHistoryJa()

  readonly operatorRoleList: OperatorRoleListJa = new OperatorRoleListJa()

  readonly loginInput: LoginInputJa = new LoginInputJa()

  readonly eventOkDlg: EventOkDlgJa = new EventOkDlgJa()

  readonly eventAssignDlg: EventAssignDlgJa = new EventAssignDlgJa()

  readonly csvNegative: CsvNegativeJa = new CsvNegativeJa()

  readonly eventNegativeDlg: EventNegativeDlgJa = new EventNegativeDlgJa()

  readonly eventMemoDlg: EventMemoDlgJa = new EventMemoDlgJa()

  readonly eventHoldDlg: EventHoldDlgJa = new EventHoldDlgJa()

  readonly operatorAdd: OperatorAddJa = new OperatorAddJa()

  readonly csvAuthori: CsvAuthoriJa = new CsvAuthoriJa()

  readonly eventReportNegative: EventReportNegativeJa = new EventReportNegativeJa()

  readonly csvUpdate: CsvUpdateJa = new CsvUpdateJa()

  readonly commonDateSelectionField: CommonDateSelectionFieldJa = new CommonDateSelectionFieldJa()

  readonly topMenuBar: TopMenuBarJa = new TopMenuBarJa()

  readonly systemError: SystemErrorJa = new SystemErrorJa()

  readonly commonDateTimeSelectionField: CommonDateTimeSelectionFieldJa = new CommonDateTimeSelectionFieldJa()

  readonly commonAllSelectionParts: CommonAllSelectionPartsJa = new CommonAllSelectionPartsJa()

  readonly commonConfirmDialog: CommonConfirmDialogJa = new CommonConfirmDialogJa()

  readonly commonPagingControlParts: CommonPagingControlPartsJa = new CommonPagingControlPartsJa()

  readonly allowedIpAddress = new AllowedIpAddressJa()

  readonly whiteAdd = new WhiteAddJa()

  readonly whiteList = new WhiteListJa()

  readonly trialProgress = new TrialProgressJa()

  readonly releasedRuleList = new ReleasedRuleListJa()

  readonly apiConnectionInfo = new ApiConnectionInfoJa()
}
