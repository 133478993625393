import { Const } from "@/common/globals"
import { RestoreLoginDataOptions } from "@/common/interfaces/RestoreLoginDataOptions"
import { LoginInfo } from "@/models/persistentEntryPoint/LoginInfo"
import { persistentDataModule } from "@/stores/modules/PersistentDataModule"
import { TransitUtils } from "@/utils/TransitUtils"

/**
 * ログイン情報をLocalStorageから取得します。
 */
export async function restoreLoginData(options?: RestoreLoginDataOptions) {
  if (options === undefined || options.callback === undefined) {
    // ストアの状態を取得中に変更
    persistentDataModule.reset()
  }

  // ストアのログイン情報取得
  const existingLogine = persistentDataModule.loginData
  // ローカルストレージからデータ取得
  const item: any = localStorage.getItem(Const.LocalStorageItemKey)
  const restore: LoginInfo = JSON.parse(item)

  // ログインしていない場合、ログイン画面に遷移
  // 既存のログイン情報と一致していない場合も、ログイン画面へ遷移
  if (
    restore == null ||
    restore.loginToken === "" ||
    (existingLogine.loginUser != null &&
      (restore.loginUser == null || existingLogine.loginUser.userId !== restore.loginUser.userId)) ||
    (existingLogine.loginOperator != null &&
      (restore.loginOperator == null || existingLogine.loginOperator.operatorId !== restore.loginOperator.operatorId))
  ) {
    // ログイン画面へ遷移
    if (options && options.callback) {
      options.callback(undefined)
    }
    TransitUtils.transitToAny(null, "/login")
  } else {
    await persistentDataModule.restoreLoginInfo({
      loginInfo: restore,
      options,
    })
  }
}
