import { AbstractLocaleMessageObject } from "@/i18n/AbstractLocaleMessageObject"

import { AllowedIpAddressEn } from "%%/labels/en/AllowedIpAddressEn"
import { ApiConnectionInfoEn } from "%%/labels/en/ApiConnectionInfoEn"
import { CommonAllSelectionPartsEn } from "%%/labels/en/CommonAllSelectionPartsEn"
import { CommonConfirmDialogEn } from "%%/labels/en/CommonConfirmDialogEn"
import { CommonDateSelectionFieldEn } from "%%/labels/en/CommonDateSelectionFieldEn"
import { CommonDateTimeSelectionFieldEn } from "%%/labels/en/CommonDateTimeSelectionFieldEn"
import { CommonPagingControlPartsEn } from "%%/labels/en/CommonPagingControlPartsEn"
import { CommonTitleHeaderEn } from "%%/labels/en/CommonTitleHeaderEn"
import { CsvAuthoriEn } from "%%/labels/en/CsvAuthoriEn"
import { CsvAuthoriHistoryEn } from "%%/labels/en/CsvAuthoriHistoryEn"
import { CsvNegativeEn } from "%%/labels/en/CsvNegativeEn"
import { CsvUpdateEn } from "%%/labels/en/CsvUpdateEn"
import { EventAssignDlgEn } from "%%/labels/en/EventAssignDlgEn"
import { EventCsvDownloadDlgEn } from "%%/labels/en/EventCsvDownloadDlgEn"
import { EventDetailEn } from "%%/labels/en/EventDetailEn"
import { EventHoldDlgEn } from "%%/labels/en/EventHoldDlgEn"
import { EventItemDisplayFormatEditEn } from "%%/labels/en/EventItemDisplayFormatEditEn"
import { EventLabelDlgEn } from "%%/labels/en/EventLabelDlgEn"
import { EventListEn } from "%%/labels/en/EventListEn"
import { EventMemoDlgEn } from "%%/labels/en/EventMemoDlgEn"
import { EventNegativeDlgEn } from "%%/labels/en/EventNegativeDlgEn"
import { EventNgDlgEn } from "%%/labels/en/EventNgDlgEn"
import { EventOkDlgEn } from "%%/labels/en/EventOkDlgEn"
import { EventReportNegativeEn } from "%%/labels/en/EventReportNegativeEn"
import { LoginInputEn } from "%%/labels/en/LoginInputEn"
import { MenuLabelsEn } from "%%/labels/en/MenuLabelsEn"
import { NegativeAddEn } from "%%/labels/en/NegativeAddEn"
import { NegativeDetailEn } from "%%/labels/en/NegativeDetailEn"
import { NegativeHistoryEn } from "%%/labels/en/NegativeHistoryEn"
import { NegativeListEn } from "%%/labels/en/NegativeListEn"
import { OperatorAddEn } from "%%/labels/en/OperatorAddEn"
import { OperatorDetailEn } from "%%/labels/en/OperatorDetailEn"
import { OperatorEditEn } from "%%/labels/en/OperatorEditEn"
import { OperatorListEn } from "%%/labels/en/OperatorListEn"
import { OperatorRoleListEn } from "%%/labels/en/OperatorRoleListEn"
import { PasswordChangeEn } from "%%/labels/en/PasswordChangeEn"
import { PasswordChangeRequestEn } from "%%/labels/en/PasswordChangeRequestEn"
import { ReleasedRuleListEn } from "%%/labels/en/ReleasedRuleListEn"
import { SystemErrorEn } from "%%/labels/en/SystemErrorEn"
import { TopMenuBarEn } from "%%/labels/en/TopMenuBarEn"
import { WhiteAddEn } from "%%/labels/en/WhiteAddEn"
import { WhiteListEn } from "%%/labels/en/WhiteListEn"
import { ILabels } from "%%/labels/interfaces"

import { TrialProgressEn } from "./en/TrialProgressEn"

export class LabelsEn extends AbstractLocaleMessageObject implements ILabels {
  readonly allowedIpAddress = new AllowedIpAddressEn()

  readonly commonAllSelectionParts = new CommonAllSelectionPartsEn()

  readonly commonConfirmDialog = new CommonConfirmDialogEn()

  readonly commonDateSelectionField = new CommonDateSelectionFieldEn()

  readonly commonDateTimeSelectionField = new CommonDateTimeSelectionFieldEn()

  readonly commonPagingControlParts = new CommonPagingControlPartsEn()

  readonly commonTitleHeader = new CommonTitleHeaderEn()

  readonly csvAuthoriHistory = new CsvAuthoriHistoryEn()

  readonly csvAuthori = new CsvAuthoriEn()

  readonly csvNegative = new CsvNegativeEn()

  readonly csvUpdate = new CsvUpdateEn()

  readonly eventAssignDlg = new EventAssignDlgEn()

  readonly eventCsvDownloadDlg = new EventCsvDownloadDlgEn()

  readonly eventDetail = new EventDetailEn()

  readonly eventHoldDlg = new EventHoldDlgEn()

  readonly eventItemDisplayFormatEdit = new EventItemDisplayFormatEditEn()

  readonly eventLabelDlg = new EventLabelDlgEn()

  readonly eventList = new EventListEn()

  readonly eventMemoDlg = new EventMemoDlgEn()

  readonly eventNegativeDlg = new EventNegativeDlgEn()

  readonly eventNgDlg = new EventNgDlgEn()

  readonly eventOkDlg = new EventOkDlgEn()

  readonly eventReportNegative = new EventReportNegativeEn()

  readonly loginInput = new LoginInputEn()

  readonly menuLabels = new MenuLabelsEn()

  readonly negativeAdd = new NegativeAddEn()

  readonly negativeDetail = new NegativeDetailEn()

  readonly negativeHistory = new NegativeHistoryEn()

  readonly negativeList = new NegativeListEn()

  readonly operatorAdd = new OperatorAddEn()

  readonly operatorDetail = new OperatorDetailEn()

  readonly operatorEdit = new OperatorEditEn()

  readonly operatorList = new OperatorListEn()

  readonly operatorRoleList = new OperatorRoleListEn()

  readonly passwordChange = new PasswordChangeEn()

  readonly passwordChangeRequest = new PasswordChangeRequestEn()

  readonly systemError = new SystemErrorEn()

  readonly topMenuBar = new TopMenuBarEn()

  readonly whiteAdd = new WhiteAddEn()

  readonly whiteList = new WhiteListEn()

  readonly trialProgress = new TrialProgressEn()

  readonly releasedRuleList = new ReleasedRuleListEn()

  readonly apiConnectionInfo = new ApiConnectionInfoEn()
}
