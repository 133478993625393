import { AbstractLocaleMessageObject } from "@/i18n/AbstractLocaleMessageObject"

import { IApiConnectionInfo } from "%%/labels/interfaces"

export class ApiConnectionInfoEn extends AbstractLocaleMessageObject implements IApiConnectionInfo {
  readonly companyId = "Company ID"
  readonly applicationId = "Application ID"
  readonly secret = "Secret"
  readonly model = "Detection Model"
}
