import { RouteConfig } from "vue-router"

export const ApiConnectionInfoRouteConfig: RouteConfig = {
  path: "/apiConnectionInfo",
  name: "ApiConnectionInfo",
  component: () => import("%%/views/apiConnectionInfo/ApiConnectionInfo.vue"),
  meta: {
    reload: false,
    authRequired: true,
  },
}
