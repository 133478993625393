import { AbstractLocaleMessageObject } from "@/i18n/AbstractLocaleMessageObject"

export class MenuLabelsJa extends AbstractLocaleMessageObject {
  readonly event: string = "イベント"

  readonly report: string = "レポート"

  readonly csvAuthori: string = "CSV登録・更新"

  readonly negativeList: string = "ネガティブ登録"

  readonly whiteList: string = "ホワイト登録"

  readonly operator: string = "管理"

  readonly operatorList: string = "オペレータ情報一覧"

  readonly operatorRoleList: string = "ロール情報一覧"

  readonly allowedIpAddress: string = "許可IPアドレス"

  readonly authoriCondition: string = "審査条件"

  readonly apiConnectionInfo: string = "API接続情報"
}
